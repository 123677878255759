<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{title}}</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                <div class="pb-2">
                    <!-- form -->
                    <form  class="row gy-1 gx-2" onsubmit="return false" novalidate="novalidate">
                        <div class="col-12 px-2">
                            <label class="form-label" for="chartName">Account Name</label>
                            <div class="input-group input-group-merge">
                                <input
                                    v-model="formData.name"
                                    id="chartName"
                                    class="form-control add-credit-card-mask" 
                                    type="text"
                                    placeholder="Account Name"
                                >
                                <span class="input-group-text cursor-pointer p-25" >
                                    <span class="add-card-type"></span>
                                </span>
                            </div>
                        </div>
                        
                        <div v-if="isGroup" class="col-12 px-2">
                            <label class="form-label" for="chartName">Parent Group</label>
                            <div class="input-group input-group-merge">
                                <input
                                    :value="parentGroup"
                                    id="chartName"
                                    class="form-control add-credit-card-mask" 
                                    type="text"
                                    readonly
                                    placeholder="Parent Group"
                                >
                                <span class="input-group-text cursor-pointer p-25" >
                                    <span class="add-card-type"></span>
                                </span>
                            </div>
                        </div>
                        
                        <div v-if="isGroup" class="col-12 px-2">
                            <label class="form-label" for="modalEditUserStatus">Default Account Type</label>

                            <select
                                class="form-select"
                                v-model="formData.account_slug"
                            >
                                <option
                                    v-for="(slug, key) in accountSlug"
                                    :key="key"
                                    :value="key"
                                >
                                    {{slug}}
                                </option>
                            </select>
                        </div>
                        
                        <div v-if="!isGroup" class="col-12 px-2">
                            <label class="form-label" for="modalEditUserStatus">Select Account Type</label>

                            <select
                                class="form-select"
                                v-model="formData.account_type"
                            >
                                <option
                                    v-for="st in typeKeys"
                                    :key="st"
                                    :value="st"
                                >
                                    {{ accountType[st] }}
                                </option>
                            </select>
                        </div>
                        
                        <div v-if="eventName === 'onHeadAdd'" class="col-12 px-2">
                            <label class="form-label" for="modalEditUserStatus">Select TDS Reference</label>
                            <v-select
                                placeholder="Select TDS Reference"
                                v-model="formData.tds_rate_id"
                                :options="tdsRateList"
                                label="name"
                                :reduce="item => item.id"
                            />
                        </div>
                        
                        <div v-if="eventName === 'onHeadAdd'" class="col-12 px-2">
                            <label class="form-label" for="modalEditUserStatus">Select VDS Reference</label>
                            <v-select
                                placeholder="Select VDS Reference"
                                v-model="formData.vds_rate_id"
                                :options="vdsRateList"
                                label="name"
                                :reduce="item => item.id"
                            />
                        </div>

                        <div class="col-12 text-center">
                            <hr class="m-0">
                            <button @click="$emit(eventName)" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Submit
                            </button>
                            <button @click="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChartCreateModal',
    data: () => ({
        isOpen: false
    }),
    computed: {
        typeKeys() {
            return Object.keys(this.accountType)
        },
    },
    methods: {
        toggleModal () {
            this.isOpen = !this.isOpen
        }
    },
    props: {
        formData: Object,
        accountType: Object,
        accountSlug: Object,
        parentGroup: String,
        isGroup: {
            type: Boolean,
            default: false
        },
        loading: Boolean,
        title: String,
        eventName: String,
        tdsRateList: {
            type: Array,
            default: []
        },
        vdsRateList: {
            type: Array,
            default: []
        }
    }
}
</script>

<style scoped>
.btn-close {
    /* margin-left: 200px; */
    /* font-size: 32px; */
    /* margin-top: 5px; */
    width: 30px;
    height: 30px;
    background-color: #E4E6EB;
    border-radius: 50%;
  }
  .btn-close:hover {
    width: 30px;
    height: 30px;
    background-color: #D8DADF;
  }
@media screen and (min-width: 1200px) {
  .btn-close {
    /* margin-left: 200px; */
    /* font-size: 32px; */
    /* margin-top: 5px; */
    width: 30px;
    height: 30px;
    background-color: #E4E6EB;
    border-radius: 50%;
    /* margin-right: 30px; */
  }
  .btn-close:hover {
    width: 30px;
    height: 30px;
    background-color: #D8DADF;
  }
}
</style>
